

/* Testing ################ */

.header h1 {
  margin: 0;
  padding-top: 6vh;
}

.me {
  text-align: center;
}

.me img {
  margin-top: 8vh;
  max-height: 50vh;
  max-width: 90vw;
  padding-bottom: 40vh;
}

.social-icons {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  height: 30px;
  width: auto;
}


.header {
  text-align: center;
}

.header a {
  color: black;
}