.light-theme {
  .scroll-indicator * {
    background-color: transparent;
  }

  .scroll-arrow {
    color: black;
  }

  .scroll-arrow .arrow {
    border-top: 10px solid black;
  }
}



.scroll-indicator {
  position: fixed;
  right: 50%;
  bottom: 20px;
  transform: translateX(50%);
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 10px; /* Add padding for better readability */
  border-radius: 8px; /* Rounded corners */
  background-color: transparent;
}

.scroll-indicator.show {
  opacity: 1;
}

.scroll-arrow {
  cursor: pointer;
  color: black;
  text-align: center;
  font-size: 17px;
}

.scroll-arrow .arrow {
  display: block;
  width: 0;
  height: 0;
  margin: 5px auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid black;
}
