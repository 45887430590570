/* Container for the detailed article page */
.article-detail-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Article title */
.article-detail-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  line-height: 1.2;
}

/* Article metadata (e.g., date) */
.article-meta {
  font-size: 1em;
  color: #999;
  text-align: center;
}

/* Article content */
.article-content {
  font-size: 1.125em;
  color: #444;
  line-height: 1.8;
  text-align: justify;
}

/* Optional: Add some padding around images in the article content */
.article-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Optional: Style for block quotes in the article content */
.article-content blockquote {
  font-style: italic;
  color: #555;
  border-left: 4px solid #ddd;
  margin: 30px 0;
  padding-left: 20px;
}

/* Optional: Style for links in the article content */
.article-content a {
  color: #0066cc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-content a:hover {
  color: #004999;
}

.article-content h3 {
    margin-bottom: -0.5em;
}


.back {
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content vertically */
}

.back a {
  color: gray;
}
