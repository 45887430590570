
/* Grid container */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  padding: 5%;
  margin-bottom: 5em;

  column-gap: 0.75%;
  row-gap: 2.5%;
}


@media (max-width: 1500px) {
  .photo-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1500px) {
  .photo-caption {
    font-size: calc(0.45em + 0.65vw) !important;
  }
}


.photo-item img {
  margin: auto;
  display: block;
  margin-top: auto;
  max-height: 85%;
  max-width: 96%;
  margin-bottom: 0;
}

.photo-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  transition: transform 0.1s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  aspect-ratio: 1;
}


.photo-item:hover {
  transform: scale(1.005);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.photo-caption {
  position: relative;
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
  font-size: calc(0.5em + 0.4vw);
  font-weight: 300;
  color: rgb(73, 73, 73);
}


.photo-caption.landscape {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 5%;
}

.photo-caption.portrait {
  position: relative;
  margin-bottom: 4%;
  margin-top: 2.5%;
}

/* ############ portrait landscape ################ */

.photo-item img.landscape {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}



.photo-item img.portrait {
  max-height: 25.5vw;
}

@media (max-width: 1500px) {
  .photo-item img.portrait {
    max-height: 38.5vw;
  }
}




/* ########### HEADER ############### */

.gallery-header {
  margin-top: calc(1em + 2%);
  margin-bottom: calc(1em - 5%);
  text-align: center;
}

.gallery-header h1 {
  font-size: calc(1em + 3vw);
  font-weight: lighter;
  margin: 0;
}

.gallery-header h3 {
  font-size: calc(0.5em + 1.5vw);
  font-weight: lighter;
  margin-top: 0.2em;
  margin-bottom: 0;
}




/* ########## Fullscreen CSS ############ */


.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.90);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-overlay img {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  cursor: zoom-out;
}



/* ###### Fonts ##### */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
.photo-caption {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}


/* FORMATTING FOR PHONE */
@media (pointer:none), (pointer:coarse) {
  .photo-caption {
    font-size: calc(0.45em + 0.65vw) !important;
  }

  .photo-item img.landscape {
    top: 45%;
  }
  
  .photo-item img.portrait {
    max-height: calc(75%);
  }
  
  .photo-caption.landscape {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 0%;
  }
}



.photo-caption {
  width: 90%;
}



/* NEW */
.photo-item {
  overflow: hidden;
}



.photo-item {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}